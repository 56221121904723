/* @override http://localhost/bxslider_v3_plugin/css/styles.css */

/*
 * To change the color scheme of slider change each 
 * background property for each of the five styles below
 */

/*next button*/
.bx-next {
	position:absolute;
	top:40%;
	right:-50px;
	z-index:999;
	width: 30px;
	height: 30px;
	text-indent: -999999px;
	background: url('../img/gray_next.png') no-repeat 0 -30px;
}

/*previous button*/
.bx-prev {
	position:absolute;
	top:40%;
	left:-50px;
	z-index:999;
	width: 30px;
	height: 30px;
	text-indent: -999999px;
	background: url('../img/gray_prev.png') no-repeat 0 -30px;
}

/*pager links*/
.bx-pager a {
	margin-right: 5px;
	color: #fff;
	padding: 3px 8px 3px 6px;
	font-size: 12px;
	zoom:1;
	background: url('../img/gray_pager.png') no-repeat 0 -20px;
}

/*auto start button*/
.bx-auto .start {
	background: url('../img/gray_auto.png') no-repeat 0 2px;
	padding-left: 13px;
}

/*auto stop button*/
.bx-auto .stop {
	background: url('../img/gray_auto.png') no-repeat 0 -14px;
	padding-left: 13px;
}

/*
 * End color scheme styles
 */


/*next/prev button hover state*/
.bx-next:hover,
.bx-prev:hover {
	background-position: 0 0;
}

/*pager links hover and active states*/
.bx-pager .pager-active,
.bx-pager a:hover {
	background-position: 0 0;
}

/*pager wrapper*/
.bx-pager {
	text-align:center;
	padding-top: 7px;
	font-size:12px;
	color:#666;
}

/*captions*/
.bx-captions {
	text-align:center;
	font-size: 12px;
	padding: 7px 0;
	color: #666;
}

/*auto controls*/
.bx-auto {
	text-align: center;
	padding-top: 15px;
}

.bx-auto a {
	color: #666;
	font-size: 12px;
}











